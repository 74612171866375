export function countdownToMidnight(
	endTimestamp, prefix, element, finishedText) {
	const now = new Date();
	const end = new Date(endTimestamp * 1000);
	const time_range = end.getTime() - now.getTime();
	if (time_range < (24 * 60 * 60 * 1000) && time_range > 1000) {
		const hours = Math.floor(
			(time_range % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((time_range % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((time_range % (1000 * 60)) / 1000);

		const hours_string = hours > 0 ? `${hours}h ` : '';
		const minutes_string = minutes > 0 ? `${minutes}m ` : '';
		const seconds_string = seconds > 0 ? `${seconds}s ` : '';
		element.textContent = `${prefix}${hours_string}${minutes_string}${seconds_string}`;
	} else if (time_range <= 1000) {
		window.clearInterval(element.interval);
		window.setTimeout(() => {
			element.textContent = finishedText;
		}, 1000);
	}
}
