var _a;
import { otto } from "./nexus.mjs";
const { activate, logger, deactivate, Level, status } = ((_a = window.o_global) == null ? void 0 : _a.debug) ?? {};
const { sendLog } = otto.debug ?? {};
export {
  Level,
  activate,
  deactivate,
  logger,
  sendLog,
  status
};
/*                              */
